import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { typeColumnOptions } from '../columnsUtils';
import OptionalWithSelectAutocomplete from './OptionalWithSelectAutocomplete';

function TypeAutocomplete({
  columnMappings,
  handleMappingChange,
  validCsvHeaders,
  isCsvHeaderValid,
  handleUnitChange,
  columnOptions,
  updateColumnOptions,
}) {
  const col = useMemo(() => typeColumnOptions(columnOptions), [columnOptions]);
  const { t } = useTranslation();

  const updateOptions = (val, boolean) => {
    updateColumnOptions(col.name, {
      ...col,
      toFill: boolean,
      typeValue: val,
    });
  };

  const onSelectChange = (newValue) => {
    updateColumnOptions(col.name, {
      ...col,
      typeValue: newValue,
    });
  };

  const selectOptions = [
    t('inventory_item.type.laying'),
    t('inventory_item.type.standing'),
    t('inventory_item.type.other')];

  return (
    <>
      <OptionalWithSelectAutocomplete
        columnMappings={columnMappings}
        handleMappingChange={handleMappingChange}
        validCsvHeaders={validCsvHeaders}
        isCsvHeaderValid={isCsvHeaderValid}
        handleUnitChange={handleUnitChange}
        updateOptions={updateOptions}
        colOptions={col}
        defaultState={t('inventory_item.type.laying')}
        onSelectChange={onSelectChange}
        selectOptions={selectOptions}
      />
      <Box>
        <Accordion elevation={0} sx={{ backgroundColor: 'lightGray.main' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="subtitle2">{t('create_inventory.container_type_info')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle2" sx={{ whiteSpace: 'pre-line' }}>
              {t('create_inventory.container_type_info_extended')}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}

export default TypeAutocomplete;
