/* eslint-disable import/prefer-default-export */

import { ColumnMappings, GlobalState } from 'components/types';
import { FormValues } from './types';

export const getDefaultFormValues = (
  t: any,
  itemData: Record<string, any>,
  columnMappings: ColumnMappings,
  globalState: GlobalState,
  previousItemFormValues: Partial<FormValues> | undefined,
  fillingStatusFromValue: (value: any) => string,
): FormValues => {
  const fillingStatus = fillingStatusFromValue(itemData[columnMappings.filling.csvName]);
  const isEmpty = fillingStatus === 'empty';

  return {
    totalCapacity: {
      value: itemData[columnMappings.totalCapacity.csvName] || '',
      unit: columnMappings.totalCapacity.unit,
    },
    usableCapacity: {
      value: itemData[columnMappings.usableCapacity.csvName] || '',
      unit: columnMappings.usableCapacity.unit,
    },
    liquidVolume: {
      value: itemData[columnMappings.liquidVolume.csvName] || '',
      unit: columnMappings.liquidVolume.unit,
    },
    tav: (!isEmpty && previousItemFormValues?.tav) || {
      value: itemData[columnMappings.tav.csvName] || '',
      unit: globalState.userUnits.alcoholUnit,
    },
    tavTemp: (!isEmpty && previousItemFormValues?.tavTemp) || {
      value: itemData[columnMappings.tavTemperature.csvName] || '',
      unit: globalState.userUnits.temperatureUnit,
    },
    volumeTemp: (!isEmpty && previousItemFormValues?.volumeTemp) || {
      value: itemData[columnMappings.volumeTemperature.csvName] || '',
      unit: globalState.userUnits.temperatureUnit,
    },
    diameterBond: {
      value: itemData[columnMappings.heightUnderBung.csvName] || '',
      unit: columnMappings.heightUnderBung.unit,
    },
    liquidHeight: {
      value: itemData[columnMappings.liquidHeight.csvName]
        || '',
      unit: columnMappings.heightUnderBung.unit,
    },
    emptyHeight: {
      value: itemData[columnMappings.emptyHeight.csvName]
        || '',
      unit: columnMappings.heightUnderBung.unit,
    },
  };
};
