/* eslint-disable import/prefer-default-export */
export const getDefaultFormValues = (
  t,
  itemData,
  columnMappings,
  globalState,
  previousItemFormValues,
  fillingStatusFromValue,
) => {
  const fillingStatus = fillingStatusFromValue(itemData[columnMappings.filling.csvName]);
  const isEmpty = fillingStatus === 'empty';

  let measurementType = '';
  if (itemData[columnMappings.liquidVolume.csvName] !== '') {
    measurementType = 'liquidVolume';
  } else if (itemData[columnMappings.emptyHeight.csvName] !== '') {
    measurementType = 'emptyHeight';
  } else {
    measurementType = 'liquidHeight';
  }

  return {
    totalCapacity: {
      value: itemData[columnMappings.totalCapacity.csvName] || '',
      unit: columnMappings.totalCapacity.unit,
    },
    usableCapacity: {
      value: itemData[columnMappings.usableCapacity.csvName] || '',
      unit: columnMappings.usableCapacity.unit,
    },
    diameterBond: {
      value: itemData[columnMappings.heightUnderBung.csvName] || '',
      unit: columnMappings.heightUnderBung.unit,
    },
    liquidHeight: {
      value: itemData[columnMappings.liquidHeight.csvName]
        || '',
      unit: columnMappings.heightUnderBung.unit,
    },
    emptyHeight: {
      value: itemData[columnMappings.emptyHeight.csvName]
        || '',
      unit: columnMappings.heightUnderBung.unit,
    },
    liquidVolume: {
      value: itemData[columnMappings.liquidVolume.csvName] || '',
      unit: columnMappings.liquidVolume.unit,
    },
    measurementType,
    tav: (!isEmpty && previousItemFormValues?.tav)
        || {
          value: itemData[columnMappings.tav.csvName]
        || '',
          unit: globalState.userUnits.alcoholUnit,
        },
    tavTemp: (!isEmpty && previousItemFormValues?.tavTemp)
        || {
          value: itemData[columnMappings.tavTemperature.csvName]
        || '',
          unit: globalState.userUnits.temperatureUnit,
        },
    volumeTemp: (!isEmpty && previousItemFormValues?.volumeTemp)
        || {
          value: itemData[columnMappings.volumeTemperature.csvName]
        || '',
          unit: globalState.userUnits.temperatureUnit,
        },
  };
};
