const containerTypeMappings = {
  standing: {
    options: ['standing', 'standing cask', 'standing barrel', 'upright',
      'vertical', 's', 'd', 'debout', 'fût debout', 'fut debout'],
    subsets: {
      debout: ['couché', 'debout'],
      upright: ['horizontal', 'upright'],
      standing: ['laying', 'standing'],
      'standing cask': ['laying cask', 'standing cask'],
      'standing barrel': ['laying barrel', 'standing barrel'],
      vertical: ['flat', 'vertical'],
      s: ['l', 's'],
      d: ['c', 'd'],
      'fût debout': ['fût couché', 'fût debout'],
      'fut debout': ['fut couché', 'fut debout'],
    },
  },
  laying: {
    options: ['laying', 'laying cask', 'laying barrel', 'horizontal', 'l', 'flat',
      'c', 'couché', 'couche', 'fût couché', 'fut couché', 'fut couche'],
    subsets: {
      couché: ['couché', 'debout'],
      couche: ['couché', 'debout'],
      horizontal: ['horizontal', 'upright'],
      laying: ['laying', 'standing'],
      'laying cask': ['laying cask', 'standing cask'],
      'laying barrel': ['laying barrel', 'standing barrel'],
      flat: ['flat', 'vertical'],
      l: ['l', 's'],
      c: ['c', 'd'],
      'fût couché': ['fût couché', 'fût debout'],
      'fut couché': ['fut couché', 'fut debout'],
      'fut couche': ['fut couche', 'fut debout'],
    },
  },
};

export function typeFromValue(value) {
  const types = Object.keys(containerTypeMappings);
  for (const type of types) {
    if (containerTypeMappings[type].options.includes(value.toLowerCase())) {
      return type;
    }
  }
  return 'other';
}

function hasUpperCase(str) {
  return str.toLowerCase() !== str;
}

export function formattedTypeBasedOnRead(value) {
  const type = typeFromValue(value);
  if (!type) return value;

  const { subsets } = containerTypeMappings[type];
  for (const subset in subsets) {
    if (subsets[subset].includes(value)) {
      return hasUpperCase(value) ? subset.toUpperCase() : subset;
    }
  }
  return value;
}

const normalizeInput = (input) => input.toString().trim().toLowerCase();

const parseContainerType = (input) => {
  const normalizedInput = normalizeInput(input);

  for (const [type, { options }] of Object.entries(containerTypeMappings)) {
    if (options.includes(normalizedInput)) {
      return type;
    }
  }

  return null;
};

export const getOptionsForInput = (input) => {
  const normalizedInput = normalizeInput(input);
  const type = parseContainerType(normalizedInput);

  if (type) {
    const { subsets } = containerTypeMappings[type];
    return subsets[normalizedInput] || [];
  }

  return [];
};
