import { fillingStatusFromValue } from 'components/utils/fillingType';
import Papa from 'papaparse';
import { round } from 'components/utils';
import { typeFromValue } from 'components/utils/containerType';
import decimalsMask from './decimalsMask';

const getContainerReference = (item, inventory) => {
  const ivtContainerIdMapping = inventory.columnMappings.ivtContainerId.csvName;
  if (Array.isArray(ivtContainerIdMapping)) {
    return ivtContainerIdMapping.map((colName) => item[colName]).join(' / ');
  }
  return item[ivtContainerIdMapping];
};

export const isContainerDataReady = (container, inventory, t) => {
  const errors = [];
  const fillingStatus = fillingStatusFromValue(container[inventory.columnMappings.filling.csvName]);
  const liquidVolumeDirectlyProvided = !!container[inventory.columnMappings.liquidVolume.csvName];

  const requiredFieldsBasedOnStatus = [
    inventory.columnMappings.tav.csvName,
    inventory.columnMappings.tavTemperature.csvName,
    inventory.columnMappings.volumeTemperature.csvName,
  ];

  if (!liquidVolumeDirectlyProvided && fillingStatus === 'full') {
    requiredFieldsBasedOnStatus.push(inventory.columnMappings.usableCapacity.csvName);
  }

  if (!liquidVolumeDirectlyProvided && fillingStatus !== 'full') {
    requiredFieldsBasedOnStatus.push(inventory.columnMappings.totalCapacity.csvName);
    requiredFieldsBasedOnStatus.push(inventory.columnMappings.heightUnderBung.csvName);

    const emptyHeight = !!container[inventory.columnMappings.emptyHeight.csvName];
    const liquidHeight = !!container[inventory.columnMappings.liquidHeight.csvName];

    if (!emptyHeight && !liquidHeight) {
      errors.push(t('needs_volume_calculation_field', {
        emptyHeight: inventory.columnMappings.emptyHeight.csvName,
        liquidHeight: inventory.columnMappings.liquidHeight.csvName,
        liquidVolume: inventory.columnMappings.liquidVolume.csvName,
      }));
    }
  }

  if (!liquidVolumeDirectlyProvided
    && fillingStatus !== 'full'
    && inventory.columnMappings.type?.csvName
    && typeFromValue(container[inventory.columnMappings.type.csvName]) === 'other') {
    errors.push(t('needs_liquid_volume'));
  }

  requiredFieldsBasedOnStatus.forEach((field) => {
    if (!container[field]) {
      errors.push(t('field_invalid_or_missing', { field }));
    }
  });
  return {
    isReady: errors.length === 0,
    errors,
  };
};

export const parseNumberOrEmptyString = (value) => (value ? Number(value) : '');

export const parseNumberOrNull = (value) => (value ? Number(value) : null);

export const mergeConversionResults = (arrayToUpdate, conversionResultsCsv, rowIds, t, inventory) => {
  const conversionResults = Papa.parse(conversionResultsCsv, {
    header: true,
    dynamicTyping: false,
    skipEmptyLines: true,
    delimitersToGuess: [';', ','],
  }).data;

  // Create a map for easy access to conversion results by reference
  const conversionResultsMap = conversionResults.reduce((acc, result) => {
    acc[result.reference] = result;
    return acc;
  }, {});

  return arrayToUpdate.map((container) => {
    const result = conversionResultsMap[getContainerReference(container, inventory)];
    if (result) {
      return {
        ...container,
        [inventory.columnMappings.ABVAt20cResult.csvName]:
          parseNumberOrEmptyString(result.ABVAt20c),
        [inventory.columnMappings.pureAlcoholVolumeAt20cResult.csvName]:
          parseNumberOrEmptyString(result.pureAlcoholVolumeAt20c),
        [inventory.columnMappings.liquidVolumeResult.csvName]:
          parseNumberOrEmptyString(result.liquidVolume),
        [inventory.columnMappings.volumeAt20cResult.csvName]:
          parseNumberOrEmptyString(result.volumeAt20c),
        [inventory.columnMappings.volumeCorrectionFactorResult.csvName]:
          parseNumberOrEmptyString(result.volumeCorrectionFactor),
        [t('create_inventory.default_mapping_fields.volumeUnit')]:
          result.volumeUnit,
      };
    }
    return container;
  });
};

export const calculateEvaporationForContainer = (container, columnMappings) => {
  if (!container[columnMappings.pureAlcoholTheoretical?.csvName]) {
    return container;
  }
  const newContainer = { ...container };
  // Evaporation (dans l'unité des volumes) = Volume AP (N-1) - Volume AP (N)
  // calcul du % d'évaporation = Evaporation / Volume AP (N-1)
  const volumeAPT = Number(container[columnMappings.pureAlcoholTheoretical.csvName]);
  const volumeAP = Number(container[columnMappings.pureAlcoholVolumeAt20cResult.csvName]);
  const evaporation = volumeAPT - volumeAP;
  const percentEvaporation = (evaporation / volumeAPT) * 100;
  newContainer[columnMappings.evaporation.csvName] = round(evaporation, decimalsMask[container.LBX_unite_volume]);
  newContainer[columnMappings.percentEvaporation.csvName] = round(
    percentEvaporation,
    decimalsMask[container.LBX_unite_volume],
  );
  return newContainer;
};

function setEmptyVolumesToZero(container, columnMappings, t) {
  const columns = [
    columnMappings.pureAlcoholVolumeAt20cResult.csvName,
    columnMappings.liquidVolumeResult.csvName,
    columnMappings.volumeAt20cResult.csvName,
  ];

  const volumeUnit = columnMappings.volumeAt20cResult.unit;

  const updatedContainer = { ...container };

  columns.forEach((column) => {
    if (!updatedContainer[column]) {
      updatedContainer[column] = 0;
    }
  });

  const volumeUnitColName = t('create_inventory.default_mapping_fields.volumeUnit');

  updatedContainer[volumeUnitColName] = volumeUnit;

  return updatedContainer;
}

export function updateContainersArrayWithEmptyContainers(containersArray, selectedEmptyContainers, columnMappings, t) {
  const newArray = containersArray.map((container, index) => {
    const selectedContainer = selectedEmptyContainers.find((sc) => sc.id === container.id);
    if (selectedContainer) {
      return setEmptyVolumesToZero(container, columnMappings, t);
    }
    return container;
  });

  return newArray;
}
