import { Box, IconButton, Typography } from '@mui/material';
import React, {
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { LabelInputSelect, NumberInput } from './BoxetteInputs';

export default function EditableField({
  formik, fieldKey, translationKey, dynamicUnitName, decimalsMask,
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const { t } = useTranslation();

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1,
    }}
    >
      {!isEditMode ? (
        <>
          <Typography component="span">{`${t(translationKey)} : `}</Typography>
          <Typography component="span">
            {`${formik.values[fieldKey].value} ${dynamicUnitName(formik.values[fieldKey].unit)}`}
          </Typography>
          <IconButton onClick={() => setIsEditMode(true)} size="small">
            <EditIcon fontSize="inherit" />
          </IconButton>
        </>
      ) : (
        <LabelInputSelect
          input={(
            <NumberInput
              label={t(translationKey)}
              id={`${fieldKey}.value`}
              name={`${fieldKey}.value`}
              value={formik.values[fieldKey].value}
              onChange={formik.handleChange}
              decimalScale={decimalsMask[formik.values[fieldKey].unit]}
              onBlur={(e) => {
                formik.handleBlur(e);
                setIsEditMode(false);
              }}
            />
        )}
          select={(
            <Typography component="span" sx={{ p: 2 }}>
              {dynamicUnitName(formik.values[fieldKey].unit)}
            </Typography>
        )}
        />
      )}
      <ErrorMessage
        touched={formik.touched[fieldKey]?.value}
        error={formik.errors[fieldKey]?.value}
      />
    </Box>
  );
}

function ErrorMessage({ touched, error }) {
  return touched && error && (
    <Typography component="span" style={{ fontSize: 13, color: 'red', marginTop: '8px' }}>
      {error}
    </Typography>
  );
}
