import { getKeyByNestedProperty } from 'components/utils';
import { typeFromValue } from 'components/utils/containerType';
import { fillingStatusFromValue } from 'components/utils/fillingType';
import { deleteField, doc, updateDoc } from 'firebase/firestore';

export const variantFromInputColumnOptions = (columnOptions) => columnOptions.filter(
  (col) => col.variant === 'fromInput',
);
export const variantFromInputOptionalColumnOptions = (columnOptions) => columnOptions.filter(
  (col) => col.variant === 'fromInputOptional',
);
export const variantConversionResultColumnOptions = (columnOptions) => columnOptions.filter(
  (col) => col.variant === 'conversionResult',
);
export const variantOperatorRecordColumnOptions = (columnOptions) => columnOptions.filter(
  (col) => col.variant === 'operatorRecord',
);
export const variantEvaporationColumnOptions = (columnOptions) => columnOptions.filter(
  (col) => col.variant === 'evaporation',
);
export const variantEvaporationResultColumnOptions = (columnOptions) => columnOptions.filter(
  (col) => col.variant === 'evaporationResult',
);
export const fillingColumnOptions = (columnOptions) => columnOptions.find((col) => col.name === 'filling');
export const typeColumnOptions = (columnOptions) => columnOptions.find((col) => col.name === 'type');

export const usableCapacityColumnOptions = (columnOptions) => columnOptions.find(
  (col) => col.name === 'usableCapacity',
);
export const heightUnderBungColumnOptions = (columnOptions) => columnOptions.find(
  (col) => col.name === 'heightUnderBung',
);

export const isAnyMandatoryEvaporationColumnFilled = (columnOptions, columnMappings) => [
  ...variantEvaporationColumnOptions(columnOptions)].some((col) => col.required && !!columnMappings[col.name]?.csvName);

export const getAllMappedColumnNames = (cOpts, columnMappings) => cOpts
  .filter((col) => !!columnMappings[col.name]?.csvName)
  .map((col) => columnMappings[col.name].csvName);

export const getUnmappedRequiredColumns = (
  columnOpts,
  columnMappings,
  validCsvHeaders,
  checkCsvHeaderValidity = false,
) => columnOpts
  .filter((col) => {
    if (!col.required) return false;
    const isMapped = !!columnMappings[col.name]?.csvName;
    const isHeaderValid = checkCsvHeaderValidity ? isCsvHeaderValid(col.name, columnMappings, validCsvHeaders) : true;
    const isUnitSelected = (col.type !== 'volume' && col.type !== 'length') || !!columnMappings[col.name]?.unit;
    return !(isMapped && isHeaderValid && isUnitSelected);
  })
  .map((col) => col.name);

const isCsvHeaderValid = (columnName, columnMappings, validCsvHeaders) => {
  const mappingValue = columnMappings[columnName]?.csvName;
  if (Array.isArray(mappingValue)) {
    return mappingValue.every((value) => validCsvHeaders.includes(value));
  }
  return validCsvHeaders.includes(mappingValue);
};

const validateFillingColumn = (t, containersArray, columnMappings, startFromLine) => {
  containersArray.forEach((row, index) => {
    const fillingValue = row[columnMappings.filling.csvName];
    const fillingStatus = fillingStatusFromValue(fillingValue);
    if (fillingStatus === null) {
      throw new Error(t('create_inventory.invalid_filling_value', {
        columnName: columnMappings.filling.csvName,
        row: index + startFromLine + 1,
        fillingValue,
      }));
    }
  });
};

const validateTypeColumn = (t, containersArray, columnMappings, startFromLine) => {
  containersArray.forEach((row, index) => {
    const typeValue = row[columnMappings.type.csvName];
    const typeStatus = typeFromValue(typeValue);
    if (typeStatus === null) {
      throw new Error(t('create_inventory.invalid_filling_value', {
        columnName: columnMappings.type.csvName,
        row: index + startFromLine + 1,
        fillingValue: typeValue,
      }));
    }
  });
};

const validateUniqueivtContainerIds = (t, containersArray, columnMappings, startFromLine) => {
  const ivtContainerIdMap = new Map();
  containersArray.forEach((row, index) => {
    const csvColumnNames = Array.isArray(columnMappings.ivtContainerId.csvName)
      ? columnMappings.ivtContainerId.csvName
      : [columnMappings.ivtContainerId.csvName];

    // Check if any part of ivtContainerId contains '/'
    const ivtContainerIdParts = csvColumnNames.map((name) => row[name]);
    ivtContainerIdParts.forEach((part) => {
      if (part.toString().includes('/')) {
        throw new Error(t('create_inventory.invalid_character_in_container_id', {
          part,
          invalidChar: '/',
          line: index + startFromLine + 1,
        }));
      }
    });

    const ivtContainerId = csvColumnNames.map((name) => row[name]).join(' / ');

    if (ivtContainerIdMap.has(ivtContainerId)) {
      ivtContainerIdMap.get(ivtContainerId).push(index + startFromLine + 1); // Store line numbers (1-indexed)
    } else {
      ivtContainerIdMap.set(ivtContainerId, [index + startFromLine + 1]);
    }
  });

  const duplicateivtContainerIds = Array.from(ivtContainerIdMap.entries())
    .filter(([_, lineNumbers]) => lineNumbers.length > 1);

  if (duplicateivtContainerIds.length > 0) {
    const errorMessage = duplicateivtContainerIds.map(
      ([ivtContainerId,
        lineNumbers]) => `Code: ${ivtContainerId} ${t('at_lines')} `
        + `${lineNumbers.join(', ')}`,
    ).join('\n');

    throw new Error(`${t('create_inventory.duplicate_housing_codes', { errorMessage })}`);
  }
};

export const completedMapping = async (
  t,
  firestore,
  containersArray,
  currentCsvMapping,
  setCurrentCsvMapping,
  possibleColumnMapping,
  columnOptions,
  setContainersArray,
  globalState,
  globalActions,
  setMappingCompleted,
  setOpenDialog,
) => {
  try {
    validateUniqueivtContainerIds(t, containersArray, possibleColumnMapping, currentCsvMapping.options.startFromLine);

    const columnsToAdd = [
      ...variantFromInputOptionalColumnOptions(columnOptions),
      ...variantOperatorRecordColumnOptions(columnOptions),
      ...variantConversionResultColumnOptions(columnOptions),
    ]
      .map((col) => possibleColumnMapping[col.name].csvName);

    const evaporationColumnsToAdd = isAnyMandatoryEvaporationColumnFilled(columnOptions, possibleColumnMapping)
      ? [
        ...variantEvaporationColumnOptions(columnOptions),
        ...variantEvaporationResultColumnOptions(columnOptions)].map((col) => possibleColumnMapping[col.name].csvName)
      : [];

    // Get the unique columns to add while maintaining original column order
    const uniqueColumnsToAdd = [...new Set([...columnsToAdd, ...evaporationColumnsToAdd])];

    // Determine the full set of columns in their original order
    const originalColumns = Object.keys(containersArray[0]);
    const newColumns = uniqueColumnsToAdd.filter((col) => !originalColumns.includes(col));
    const allColumns = [...originalColumns, ...newColumns];

    const mappingColumnsAdded = containersArray.map((container) => {
      const newContainer = {};
      allColumns.forEach((colName) => {
        let cellValue = container[colName] ?? '';

        // Check if the column type is 'number' and replace commas with dots
        const optionKey = getKeyByNestedProperty(possibleColumnMapping, 'csvName', colName);
        const option = possibleColumnMapping[optionKey];
        if (option && option.cellType === 'number') {
          if (typeof cellValue !== 'number') {
            // Ensure cellValue is a string and replace commas with dots
            cellValue = String(cellValue).replace(/,/g, '.');

            // Parse the cellValue to a float number
            const parsedValue = parseFloat(cellValue);
            if (!isNaN(parsedValue)) {
              cellValue = parsedValue;
            } else {
              cellValue = '';
            }
          }
        }
        newContainer[colName] = cellValue;
        if (colName === possibleColumnMapping.filling.csvName) {
          const colOpt = fillingColumnOptions(columnOptions);
          if (colOpt.toFill) {
            newContainer[colName] = colOpt.fillingValue;
          }
        }
        if (colName === possibleColumnMapping.type.csvName) {
          const colOpt = typeColumnOptions(columnOptions);
          if (colOpt.toFill) {
            newContainer[colName] = colOpt.typeValue;
          }
        }
        if (colName === possibleColumnMapping.usableCapacity.csvName) {
          if (usableCapacityColumnOptions(columnOptions).toFill) {
            newContainer[colName] = container[possibleColumnMapping.totalCapacity.csvName] ?? '';
          }
        }
      });
      return newContainer;
    });

    validateFillingColumn(t, mappingColumnsAdded, possibleColumnMapping, currentCsvMapping.options.startFromLine);
    validateTypeColumn(t, mappingColumnsAdded, possibleColumnMapping, currentCsvMapping.options.startFromLine);
    setContainersArray(mappingColumnsAdded);
  } catch (err) {
    globalActions
      .setSnackbarMessage({ message: `${err}`, severity: 'error' });
    return;
  }

  try {
    const newCsvMapping = {
      columnMapping: possibleColumnMapping,
      columnDelimiter: currentCsvMapping.options.columnDelimiter,
      decimalSeparator: currentCsvMapping.options.decimalSeparator,
      startFromLine: currentCsvMapping.options.startFromLine,
      timestamp: new Date(),
    };

    const currentCsvMappings = globalState.csvMappings;

    const updatedCsvMappings = {
      ...currentCsvMappings,
      [currentCsvMapping.name]: newCsvMapping,
    };

    setCurrentCsvMapping({ name: currentCsvMapping.name, options: newCsvMapping });
    globalActions.setCsvMappings(updatedCsvMappings);
    const orgPath = `/organizations/${globalState.activeOrganization}/apps/inventory`;
    const orgDocRef = doc(firestore, orgPath);
    await updateDoc(orgDocRef, {
      csvMappings: updatedCsvMappings,
      defaultBarrelCsvMapping: deleteField(),
    });

    setMappingCompleted(true);
    setOpenDialog(false);
  } catch (err) {
    globalActions
      .setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' });
    throw err;
  }
};
