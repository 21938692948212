/* eslint-disable no-nested-ternary */
import * as yup from 'yup';
import {
  dynamicUnitName,
} from 'components/units/UnitDefinitionsAndMasks';

export default function validationSchemaLaying(t, alcoholUnitRelateds) {
  const validationPhrases = {
    tavValidationPhrase: t('validations.tav_validation', {
      tavMin: alcoholUnitRelateds.tavMin,
      tavMax: alcoholUnitRelateds.tavMax,
      alcoholUnit: dynamicUnitName(alcoholUnitRelateds.alcoholUnit),
    }),
    tempValidationPhrase: t('validations.temperature_validation', {
      tempMin: alcoholUnitRelateds.tempMin,
      tempMax: alcoholUnitRelateds.tempMax,
      tempUnit: dynamicUnitName(alcoholUnitRelateds.tempUnit),
    }),
  };

  const volumeFieldSchema = (validationMessage) => yup.object().shape({
    value: yup.number()
      .typeError(validationMessage)
      .min(0, validationMessage)
      .max(99999999, validationMessage),
    unit: yup.string().required(t('required')),
  });

  return yup.object().shape({
    liquidVolume: volumeFieldSchema(t('ullaging.vol_validation')),
    totalCapacity: volumeFieldSchema(t('ullaging.vol_validation')),
    usableCapacity: volumeFieldSchema(t('ullaging.vol_validation')),
    tav: yup.object().shape({
      value: yup
        .number()
        .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
        .typeError(validationPhrases.tavValidationPhrase)
        .min(alcoholUnitRelateds.tavMin, validationPhrases.tavValidationPhrase)
        .max(alcoholUnitRelateds.tavMax, validationPhrases.tavValidationPhrase),
      unit: yup.string().required(),
    }),
    tavTemp: yup.object().shape({
      value: yup
        .number()
        .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
        .typeError(validationPhrases.tempValidationPhrase)
        .min(alcoholUnitRelateds.tempMin, validationPhrases.tempValidationPhrase)
        .max(alcoholUnitRelateds.tempMax, validationPhrases.tempValidationPhrase),
      unit: yup.string().required(),
    }),
    volumeTemp: yup.object().shape({
      value: yup
        .number()
        .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
        .typeError(validationPhrases.tempValidationPhrase)
        .min(alcoholUnitRelateds.tempMin, validationPhrases.tempValidationPhrase)
        .max(alcoholUnitRelateds.tempMax, validationPhrases.tempValidationPhrase),
      unit: yup.string().required(),
    }),
  });
}
