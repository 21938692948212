import { parseNumberOrEmptyString } from 'components/admin/inventories/utils';
import { fillingStatusFromValue } from 'components/utils/fillingType';
import {
  collection, query, where, orderBy, limit, getDocs, updateDoc, doc, getDoc,
  serverTimestamp,
} from 'firebase/firestore';

/**
 * Syncs the containersArray by fetching the latest records for each container
 * and updating the containers based on those records.
 *
 * @param {Object} firestore - The Firestore instance.
 * @param {string} organizationId - The ID of the organization.
 * @param {string} inventoryId - The ID of the inventory.
 * @param {Function} setCurrentInventoryData - Function to update the local state.
 */
export default async function syncContainersArray(firestore, organizationId, inventoryId, setCurrentInventoryData) {
  const inventoryRef = doc(
    firestore,
    `/organizations/${organizationId}/apps/inventory/inventories/${inventoryId}`,
  );

  // Fetch the latest inventory data
  const inventorySnapshot = await getDoc(inventoryRef);
  if (!inventorySnapshot.exists()) {
    throw new Error('Document does not exist');
  }
  const inventoryData = inventorySnapshot.data();

  const newVolumeStatusDone = (fillingStatus, recordValues) => {
    if (fillingStatus === 'full') {
      return !!recordValues.volumeTemp.value;
    } if (fillingStatus === 'empty') {
      return true;
    }
    return !!recordValues.liquidHeight.value || !!recordValues.emptyHeight.value
      || !!recordValues.liquidVolume.value || !!recordValues.volumeTemp.value;
  };

  const newABVStatusDone = (fillingStatus, recordValues) => {
    if (fillingStatus === 'empty') {
      return true;
    }
    return !!recordValues.tav.value || !!recordValues.tavTemp.value;
  };

  const getContainerId = (item) => {
    const ivtContainerIdMapping = inventoryData.columnMappings.ivtContainerId.csvName;
    if (Array.isArray(ivtContainerIdMapping)) {
      return ivtContainerIdMapping.map((colName) => item[colName]).join(' / ');
    }
    return item[ivtContainerIdMapping];
  };

  function calculateProgressPercentageABV(containersArray) {
    const totalItems = containersArray.length;
    const completedItems = containersArray.filter((container) => container.abvStatus === 'done').length;
    return (completedItems / totalItems) * 100;
  }

  function calculateProgressPercentageVolume(containersArray) {
    const totalItems = containersArray.length;
    const completedItems = containersArray.filter((container) => container.volumeStatus === 'done').length;
    return (completedItems / totalItems) * 100;
  }

  const updatedContainersArray = await Promise.all(
    inventoryData.containersArray.map(async (container) => {
      const recordsRef = collection(firestore, `${inventoryRef.path}/records`);
      const q = query(
        recordsRef,
        where('containerId', '==', getContainerId(container)),
        orderBy('timeStamp', 'desc'),
        limit(1),
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const latestRecord = querySnapshot.docs[0].data();

        const fillingStatus = fillingStatusFromValue(container[inventoryData.columnMappings.filling.csvName]);
        return {
          ...container,
          abvStatus: newABVStatusDone(fillingStatus, latestRecord.formValues) ? 'done' : 'toDo',
          volumeStatus: newVolumeStatusDone(fillingStatus, latestRecord.formValues) ? 'done' : 'toDo',
          [inventoryData.columnMappings.totalCapacity.csvName]:
            parseNumberOrEmptyString(latestRecord.formValues.totalCapacity.value),
          [inventoryData.columnMappings.usableCapacity.csvName]:
            parseNumberOrEmptyString(latestRecord.formValues.usableCapacity.value),
          [inventoryData.columnMappings.heightUnderBung.csvName]:
            parseNumberOrEmptyString(latestRecord.formValues.diameterBond.value),
          [inventoryData.columnMappings.liquidHeight.csvName]:
            parseNumberOrEmptyString(latestRecord.formValues.liquidHeight.value),
          [inventoryData.columnMappings.emptyHeight.csvName]:
            parseNumberOrEmptyString(latestRecord.formValues.emptyHeight.value),
          [inventoryData.columnMappings.liquidVolume.csvName]:
            parseNumberOrEmptyString(latestRecord.formValues.liquidVolume.value),
          [inventoryData.columnMappings.tav.csvName]:
            parseNumberOrEmptyString(latestRecord.formValues.tav.value),
          [inventoryData.columnMappings.tavTemperature.csvName]:
            parseNumberOrEmptyString(latestRecord.formValues.tavTemp.value),
          [inventoryData.columnMappings.volumeTemperature.csvName]:
            parseNumberOrEmptyString(latestRecord.formValues.volumeTemp.value),
        };
      }
      return container;
    }),
  );

  const progressABVPercentage = calculateProgressPercentageABV(updatedContainersArray);
  const progressVolumePercentage = calculateProgressPercentageVolume(updatedContainersArray);

  // Check and update inventory status if needed
  let newStatus = inventoryData.status;
  if (newStatus === 'initial') {
    newStatus = 'inProgress';
  } else if (newStatus === 'inProgress' && progressABVPercentage === 100 && progressVolumePercentage === 100) {
    newStatus = 'readyForReview';
  }

  const updatedInventory = {
    containersArray: updatedContainersArray,
    progressABVPercentage,
    progressVolumePercentage,
    status: newStatus,
    syncContainersArrayTimestamp: serverTimestamp(),
  };

  await updateDoc(inventoryRef, updatedInventory);
  setCurrentInventoryData(updatedContainersArray);
}
