import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import {
  Dialog, Box, TextField, Button, Autocomplete,
} from '@mui/material';
import { useFirestore } from 'reactfire';
import {
  collection, doc, getDoc, serverTimestamp, updateDoc, writeBatch,
} from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import { fillingStatusFromValue, formattedFillingStatusBasedOnRead } from 'components/utils/fillingType';
import { LoadingButton } from '@mui/lab';

// Assuming formattedFillingStatusBasedOnRead and fillingStatusFromValue are imported or defined above

export default function ChangeFillingDialog({
  open, setOpen, inventoryData, itemData, setInventoryData,
}) {
  const firestore = useFirestore();
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const defaultStatus = itemData[inventoryData.columnMappings.filling.csvName];
  const [status, setStatus] = useState(defaultStatus);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getContainerId = (item) => {
    const ivtContainerIdMapping = inventoryData.columnMappings.ivtContainerId.csvName;
    if (Array.isArray(ivtContainerIdMapping)) {
      return ivtContainerIdMapping.map((colName) => item[colName]).join(' / ');
    }
    return item[ivtContainerIdMapping];
  };

  useEffect(() => {
    const newOptions = [
      formattedFillingStatusBasedOnRead(defaultStatus, 'draining'),
      formattedFillingStatusBasedOnRead(defaultStatus, 'empty'),
      formattedFillingStatusBasedOnRead(defaultStatus, 'full'),
    ].filter(Boolean); // Remove any nulls
    setOptions(newOptions);
  }, [defaultStatus]);

  const handleChange = (event, newValue) => {
    setStatus(newValue);
  };

  const handleSubmit = async () => {
    const inventoryRef = doc(
      firestore,
      `/organizations/${globalState.activeOrganization}/apps/inventory/inventories/${inventoryData.id}`,
    );

    setLoading(true);

    const docSnapInventory = await getDoc(inventoryRef);
    const latestInventoryData = { ...docSnapInventory.data(), id: inventoryData.id };

    console.log('inventory loaded');

    const containerId = getContainerId(itemData);
    let updateItemData;
    const updatedContainersArray = latestInventoryData.containersArray.map((container) => {
      if (getContainerId(container) === containerId) {
        updateItemData = {
          ...container,
          [latestInventoryData.columnMappings.filling.csvName]: status,
        };
        return updateItemData;
      }
      return container;
    });

    const update = {
      containersArray: updatedContainersArray,
    };

    const batch = writeBatch(firestore);

    const fillingStatusRecordsRef = collection(firestore, `${inventoryRef.path}/fillingStatusRecords`);

    batch.set(doc(fillingStatusRecordsRef), {
      containerId: getContainerId(itemData),
      fillingStatus: status,
      oldFillingStatus: defaultStatus,
      timeStamp: serverTimestamp(),
    });

    batch.update(inventoryRef, {
      containersArray: updatedContainersArray,
    });

    batch.commit();

    const newInventoryData = { ...latestInventoryData, ...update };

    setInventoryData(newInventoryData);
    globalActions.setSnackbarMessage({ message: t('success'), severity: 'success', duration: 2000 });
    handleClose();
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      status: defaultStatus,
    },
    onSubmit: (values) => {
      if (fillingStatusFromValue(values.status) !== null) {
        handleSubmit();
      } else {
        globalActions.setSnackbarMessage({ message: t('invalid_format'), severity: 'error' });
      }
    },
  });

  return (
    <Dialog open={open} onClose={() => {}} fullWidth>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          my: 2,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          width: '100%',
        }}
      >
        <Autocomplete
          freeSolo
          disableClearable
          sx={{ width: '100%' }}
          value={status}
          onChange={handleChange}
          inputValue={formik.values.status}
          onInputChange={(event, newInputValue) => {
            formik.setFieldValue('status', newInputValue);
          }}
          options={options}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              sx={{ width: '100%' }}
              label={t('operation.modify_filling')}
              name="status"
              error={formik.touched.status && Boolean(formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
            />
          )}
        />
        <Box sx={{
          display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%',
        }}
        >
          <Button color="error" onClick={handleClose}>{t('cancel')}</Button>
          <LoadingButton loading={loading} type="submit">{t('done')}</LoadingButton>
        </Box>

      </Box>
    </Dialog>
  );
}
