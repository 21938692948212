import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import {
  FormControl, Paper, CircularProgress, Typography, Button,
  Autocomplete,
  TextField,
  ListItem,
  ListItemText,
  InputAdornment,
} from '@mui/material';
import {
  collection, query, getDocs, orderBy, where,
  getDocsFromServer,
} from 'firebase/firestore';
import useGlobal from 'global-state/store'; // Import your global state
import { useFirestore } from 'reactfire';
import { useTranslation } from 'react-i18next';
import OrganizationsSelect from 'components/OrganizationsSelect';
import { useNavigate } from 'react-router-dom';
import { ArrowForward, Refresh } from '@mui/icons-material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import NotYetSetup from 'components/NotYetSetup';
import useMenu from 'menu-actions/useMenu';
import { LoadingButton } from '@mui/lab';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export default function SelectInventory() {
  const [globalState] = useGlobal();

  const { dispatchMenuActions } = useMenu();

  const cleanupMenuActions = useCallback(() => {
    dispatchMenuActions({ type: 'clear' });
  }, [dispatchMenuActions]);

  useEffect(() => () => cleanupMenuActions(), [cleanupMenuActions]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        px: { xs: 2, md: 10 },
        mt: { xs: 5, md: 10 },
        mb: 15,
      }}
    >
      <OrganizationsSelect />
      {globalState.userStatus === 'notYetSetup' && (
        <NotYetSetup />
      )}
      {globalState.userStatus === 'setupDone' && (
        <SelectInventoryContent />
      )}

    </Box>
  );
}

function SelectInventoryContent() {
  const [inventories, setInventories] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState('');
  const [loadingState, setLoadingState] = useState('notLoaded');
  const db = useFirestore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [globalState, globalActions] = useGlobal();

  const fetchInventories = useCallback(async (forceCacheReload = false) => {
    setLoadingState('loading');
    const q = query(
      collection(db, `organizations/${globalState.activeOrganization}/apps/inventory/inventories`),
      where('status', 'in', ['initial', 'inProgress', 'readyForReview']),
      orderBy('name'),
    );

    const querySnapshot = forceCacheReload
      ? await getDocsFromServer(q)
      : await getDocs(q);

    const inventoryData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    inventoryData.sort((a, b) => {
      const statusOrder = { initial: 2, inProgress: 1 };
      const statusA = statusOrder[a.status] || 3;
      const statusB = statusOrder[b.status] || 3;

      if (statusA !== statusB) {
        return statusA - statusB;
      }
      return a.name.localeCompare(b.name);
    });
    setInventories(inventoryData);

    const storedInventory = localStorage.getItem('inventory:defaultSelectedInventory');
    const isStoredInventoryValid = inventoryData.some((inventory) => inventory.id === storedInventory);
    setSelectedInventory(isStoredInventoryValid ? storedInventory : (inventoryData[0] && inventoryData[0].id));
    setLoadingState('loaded');
  }, [db, globalState.activeOrganization]);

  useEffect(() => {
    if (loadingState === 'notLoaded') {
      fetchInventories();
    }
  }, [db, fetchInventories, globalState.activeOrganization, loadingState]);

  const handleChange = (newValue) => {
    setSelectedInventory(newValue.id);
  };

  const handleGoToOperation = () => {
    localStorage.setItem('inventory:defaultSelectedInventory', selectedInventory);
    navigate('inventory', {
      state: {
        inventory: selectedInventory,
      },
    });
  };

  const customCheckbox = (status) => {
    if (status === 'readyForReview' || status === 'done') {
      return (
        <TaskAltIcon sx={{ color: 'flashyGreen.main', fontSize: 15 }} />
      );
    }
    return (
      <RadioButtonUncheckedIcon sx={{ fontSize: 15 }} />
    );
  };

  const currentInventory = inventories.find((inventory) => inventory.id === selectedInventory);

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          ml: 1,
          mr: 1,
          pt: 3,
          pb: 3,
          pl: 1,
          pr: 1,
          width: '100%',
          maxWidth: 800,
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ mb: 2 }}
        >
          {t('inventories')}
        </Typography>
        {loadingState === 'loaded' && inventories.length > 0 && selectedInventory && (
        <FormControl fullWidth>
          <Autocomplete
            id="inventory-autocomplete"
            disableClearable
            value={currentInventory}
            onChange={(event: any, newValue: string | null) => {
              handleChange(newValue);
            }}
            options={inventories}
            getOptionLabel={(option) => option.name}
            getOptionKey={(option) => option.id}
            renderOption={(props, option) => (
              <ListItem
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                key={option.id}
              >
                <ListItemText
                  variant="caption"
                  primary={(
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 1,
                      flexDirection: 'column',
                      width: '100%',
                    }}
                    >
                      <Typography variant="caption">
                        {option.name}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {customCheckbox(option.status)}
                        <Typography variant="caption">
                          {t(`inventory_detail.status.${option.status}`)}
                        </Typography>
                      </Box>
                    </Box>
                    )}
                />
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                sx={{ width: '100%' }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                      <InputAdornment position="end">
                        <Box sx={{
                          display: 'flex', alignItems: 'center', gap: 1,
                        }}
                        >
                          {customCheckbox(currentInventory.status)}
                          <Typography>
                            {t(`inventory_detail.status.${currentInventory.status}`)}
                          </Typography>
                        </Box>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )}
          />
        </FormControl>
        )}
        {loadingState === 'loaded' && inventories.length === 0 && (
        <Typography sx={{ mt: 2 }}>{t('operation.no_inventories_available')}</Typography>
        )}
        {loadingState !== 'loaded' && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <CircularProgress />
          </Box>
        )}
        <LoadingButton
          sx={{ mt: 2, mb: -1 }}
          loading={loadingState === 'loading'}
          variant="outlined"
          size="small"
          onClick={() => {
            if (globalState.networkState === 'offline') {
              globalActions.setSnackbarMessage({ message: t('forms.need_network'), severity: 'info' });
              return;
            }
            fetchInventories(true);
          }}
          endIcon={<CloudDownloadIcon />}
        >
          {t('refresh')}
        </LoadingButton>
      </Paper>
      {inventories.length > 0 && (
      <Button
        variant="contained"
        size="large"
        onClick={handleGoToOperation}
        endIcon={<ArrowForward />}
      >
        {t('continue')}
      </Button>
      )}
    </>
  );
}
