import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useGridApiContext,
} from '@mui/x-data-grid';
import {
  Box, Button, Paper, Popper, Switch, TextField, Typography,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

export default function CustomEditCell({
  id, field, value, editFieldForSelectedLines, setEditFieldForSelectedLines, mousePosition, hasFocus, colDef,
}) {
  const [textValue, setTextValue] = useState(value || '');
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState();
  const [inputRef, setInputRef] = useState(null);
  const originalValue = value;

  const handleTextChange = (event) => {
    setTextValue(event.target.value || '');
  };

  const handleSave = () => {
    apiRef.current.setEditCellValue({ id, field, value: textValue });
    apiRef.current.stopCellEditMode({ id, field });
  };

  const handleCancel = () => {
    apiRef.current.setEditCellValue({ id, field, value: originalValue });
    apiRef.current.stopCellEditMode({ id, field });
  };

  useEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, inputRef]);

  const handleRef = useCallback((el) => {
    setAnchorEl(el);
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <Box sx={{ position: 'relative', alignSelf: 'flex-start' }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: 'block',
          position: 'absolute',
          top: -10,
          left: -10,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom-start" style={{ zIndex: 1300 }}>
          <Paper
            sx={{
              display: 'flex', alignItems: 'center', flexDirection: 'column', p: 1, gap: 2,
            }}
            onKeyDown={handleKeyDown}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <TextField
                value={textValue}
                onChange={handleTextChange}
                inputRef={(ref) => setInputRef(ref)}
                type={colDef.type === 'number' ? 'number' : 'text'}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Switch
                  size="small"
                  checked={editFieldForSelectedLines}
                  onChange={(event) => {
                    setEditFieldForSelectedLines(event.target.checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography variant="caption">
                  {t('inventory_grid.column_bulk_change')}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 3,
              }}
            >
              <Button size="small" variant="outlined" onClick={handleSave}>
                <DoneIcon sx={{ fontSize: 12 }} />
              </Button>
              <Button size="small" variant="outlined" color="darkgray" onClick={handleCancel}>
                <CloseIcon sx={{ fontSize: 12 }} />
              </Button>
            </Box>
          </Paper>
        </Popper>
      )}
    </Box>
  );
}
