import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button, Collapse, Dialog,
  DialogActions, DialogContent, Paper, TextField, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  heightUnderBungColumnOptions,
  variantConversionResultColumnOptions, variantEvaporationColumnOptions,
  variantEvaporationResultColumnOptions, variantFromInputColumnOptions,
  variantOperatorRecordColumnOptions,
} from '../columnsUtils';
import AutocompleteAndUnitSelect from './AutocompleteAndUnitSelect';
import { handleSubmit } from './mappingUtils';
import FillingAutocomplete from './FillingAutocomplete';
import UsableCapacityAutocomplete from './UsableCapacityAutocomplete';
import SelectMapping from './SelectMapping';
import TypeAutocomplete from './TypeAutocomplete';

export default function MappingDialog({
  columnOptions, csvHeaders, currentCsvMapping, possibleColumnMapping,
  openDialog, completedMapping, cancelledMapping,
  updateColumnOptions, updateSelectedMapping, setPossibleColumnMapping,
  setCurrentCsvMapping,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [, globalActions] = useGlobal();
  const [displayEvaporation, setDisplayEvaporation] = useState(false);
  const expandIcon = () => (displayEvaporation ? <ExpandLessIcon /> : <ExpandMoreIcon />);
  const validCsvHeaders = csvHeaders.filter((header) => Boolean(header));

  const isCsvHeaderValid = (columnName) => {
    const mappingValue = possibleColumnMapping[columnName]?.csvName;
    if (Array.isArray(mappingValue)) {
      return mappingValue.every((value) => validCsvHeaders.includes(value));
    }
    return validCsvHeaders.includes(mappingValue);
  };

  const handleMappingComplete = () => {
    setLoading(true);
    completedMapping();
    setLoading(false);
  };

  const handleMappingChange = (targetColumn, type, csvColumn, unit = undefined) => {
    setPossibleColumnMapping((prev) => {
      // console.log(prev[targetColumn]);
      const newMapping = { ...prev[targetColumn], csvName: csvColumn, type };
      if (unit !== undefined) {
        newMapping.unit = unit;
      } else if (prev[targetColumn]?.unit) {
        newMapping.unit = prev[targetColumn].unit;
      }
      if (type === 'multi-string' && !Array.isArray(csvColumn)) {
        newMapping.csvName = [csvColumn]; // Ensure it's an array for multi-string
      }
      return { ...prev, [targetColumn]: newMapping };
    });
  };

  const handleUnitChange = (targetColumn, newUnit, type) => {
    setPossibleColumnMapping((prev) => {
      const updatedMappings = { ...prev };

      // Check if the target column is in variantConversionResultColumnOptions and has the same type
      const isVariantTwoColumn = variantConversionResultColumnOptions(columnOptions).some(
        (col) => col.name === targetColumn && col.type === type,
      );

      if (isVariantTwoColumn) {
        // Update the unit for all variantConversionResultColumnOptions with the same type
        variantConversionResultColumnOptions(columnOptions).forEach((col) => {
          if (updatedMappings[col.name] && col.type === type) {
            updatedMappings[col.name] = { ...updatedMappings[col.name], unit: newUnit };
          }
        });
      } else if (updatedMappings[targetColumn]) {
        updatedMappings[targetColumn] = { ...updatedMappings[targetColumn], unit: newUnit };
      }

      return updatedMappings;
    });
  };

  return (
    <Dialog open={openDialog} maxWidth="xl">
      <DialogContent sx={{
        minWidth: '60vw',
        backgroundColor: 'lightGray.main',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          width: '100%',
          maxWidth: 600,
        }}
        >
          <Paper
            elevation={0}
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              width: '100%',
            }}
          >
            <Box sx={{
              display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center',
            }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', flexGrow: 3 }}>
                {t('barrel_inventory.map_columns')}
              </Typography>
              <Box sx={{ flexGrow: 1 }}>
                <SelectMapping
                  currentCsvMapping={currentCsvMapping}
                  callback={updateSelectedMapping}
                />

              </Box>
            </Box>

            {variantFromInputColumnOptions(columnOptions).map((col) => (
              <AutocompleteAndUnitSelect
                key={col.name}
                col={col}
                columnMappings={possibleColumnMapping}
                handleMappingChange={handleMappingChange}
                validCsvHeaders={validCsvHeaders}
                isCsvHeaderValid={isCsvHeaderValid}
                handleUnitChange={handleUnitChange}
              />
            ))}
            <AutocompleteAndUnitSelect
              col={heightUnderBungColumnOptions(columnOptions)}
              columnMappings={possibleColumnMapping}
              handleMappingChange={handleMappingChange}
              validCsvHeaders={validCsvHeaders}
              isCsvHeaderValid={isCsvHeaderValid}
              handleUnitChange={handleUnitChange}
            />
            <UsableCapacityAutocomplete
              columnOptions={columnOptions}
              columnMappings={possibleColumnMapping}
              handleMappingChange={handleMappingChange}
              validCsvHeaders={validCsvHeaders}
              isCsvHeaderValid={isCsvHeaderValid}
              handleUnitChange={handleUnitChange}
              updateColumnOptions={updateColumnOptions}
            />
            <FillingAutocomplete
              columnOptions={columnOptions}
              columnMappings={possibleColumnMapping}
              handleMappingChange={handleMappingChange}
              validCsvHeaders={validCsvHeaders}
              isCsvHeaderValid={isCsvHeaderValid}
              handleUnitChange={handleUnitChange}
              updateColumnOptions={updateColumnOptions}
            />
            <TypeAutocomplete
              columnOptions={columnOptions}
              columnMappings={possibleColumnMapping}
              handleMappingChange={handleMappingChange}
              validCsvHeaders={validCsvHeaders}
              isCsvHeaderValid={isCsvHeaderValid}
              handleUnitChange={handleUnitChange}
              updateColumnOptions={updateColumnOptions}
            />
          </Paper>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              width: '100%',
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {t('create_inventory.records_mapping_title')}
            </Typography>
            {variantOperatorRecordColumnOptions(columnOptions).map((col) => (
              <AutocompleteAndUnitSelect
                key={col.name}
                col={col}
                columnMappings={possibleColumnMapping}
                handleMappingChange={handleMappingChange}
                validCsvHeaders={validCsvHeaders}
                isCsvHeaderValid={isCsvHeaderValid}
                handleUnitChange={handleUnitChange}
              />
            ))}
          </Paper>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              width: '100%',
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {t('create_inventory.results_mapping_title')}
            </Typography>
            {variantConversionResultColumnOptions(columnOptions).map((col) => (
              <AutocompleteAndUnitSelect
                key={col.name}
                col={col}
                columnMappings={possibleColumnMapping}
                handleMappingChange={handleMappingChange}
                validCsvHeaders={validCsvHeaders}
                isCsvHeaderValid={isCsvHeaderValid}
                handleUnitChange={handleUnitChange}
              />
            ))}
          </Paper>
          <Paper
            elevation={0}
            sx={{
              p: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => setDisplayEvaporation(!displayEvaporation)}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {`${t('create_inventory.evaporation_calculation')} (${t('optional')})`}
              </Typography>
              <Button sx={{ ml: 1, height: 17 }} color="inherit" variant="text">
                {expandIcon()}
              </Button>
            </Box>
            <Collapse
              in={displayEvaporation}
              sx={{
                mt: 3,
                width: '100%',
              }}
            >
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                width: '100%',
              }}
              >
                {variantEvaporationColumnOptions(columnOptions).map((col) => (
                  <AutocompleteAndUnitSelect
                    key={col.name}
                    col={col}
                    columnMappings={possibleColumnMapping}
                    handleMappingChange={handleMappingChange}
                    validCsvHeaders={validCsvHeaders}
                    isCsvHeaderValid={isCsvHeaderValid}
                    handleUnitChange={handleUnitChange}
                  />
                ))}
                {variantEvaporationResultColumnOptions(columnOptions).map((col) => (
                  <AutocompleteAndUnitSelect
                    key={col.name}
                    col={col}
                    columnMappings={possibleColumnMapping}
                    handleMappingChange={handleMappingChange}
                    validCsvHeaders={validCsvHeaders}
                    isCsvHeaderValid={isCsvHeaderValid}
                    handleUnitChange={handleUnitChange}
                  />
                ))}

              </Box>
            </Collapse>
          </Paper>
          <Paper
            elevation={0}
            sx={{
              p: 2,
            }}
          >
            <TextField
              label={t('csv_mapping_name')}
              value={currentCsvMapping.name}
              onChange={(e) => setCurrentCsvMapping((prev) => ({ ...prev, name: e.target.value }))}
              fullWidth
              margin="normal"
            />
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={cancelledMapping}>{t('cancel')}</Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => handleSubmit(
            columnOptions,
            possibleColumnMapping,
            validCsvHeaders,
            globalActions,
            t,
            handleMappingComplete,
          )}
        >
          {t('finish')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
